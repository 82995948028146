div.recipe-list button,
div.recipe-list td {
  font-size: 12px;
  vertical-align: top;
}

div.recipe-list span.pagination-info {
  display: none !important;
}

div.recipe-list div.pagination-detail span.sort-prompt {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

div.recipe-list div.page-list {
  display: none !important;
}

div.recipe-list li.page-item a {
  z-index: 0;
}

div.recipe-list .fixed-table-body {
  padding: 1px;
}

div.recipe-list table {
  border-collapse: separate;
  border-spacing: 0 1em;
}

div.recipe-list tr:hover {
  background-color: inherit;
}

div.recipe-list tr.recipe {
  outline: thin solid rgba(0, 0, 0, 0.125);
}

div.recipe-list th,
div.recipe-list td {
  border: none;
}

div.recipe-list td.nutrition {
  width: 200px;
}

div.recipe-list td.sidebar {
  width: 200px;
}

div.recipe-list .attribution {
  float: left;
}

div.recipe-list .attribution a.title {
  font-size: 1rem;
}

div.recipe-list .attribution img {
  width: 24px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

div.recipe-list .star {
  float: right;

  font-size: 1rem;
}

div.recipe-list .report-problem {
  float: left;
}

div.recipe-list .recipe table {
  clear: both;
}

div.recipe-list .nutrition {
  width: 240px;
}

div.recipe-list .nutrition div.heading {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

div.recipe-list .nutrition span.field {
  width: 90px;
  text-align: right;
}

div.recipe-list .nutrition span {
  display: inline-block;
  margin-left: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

div.recipe-list .sidebar input.servings {
  margin-top: 0.6rem;
}

div.recipe-list .sidebar span.field {
  width: 90px;
  text-align: right;
}

div.recipe-list .sidebar img.dietary-property {
  width: 24px;
  height: 24px;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

div.recipe-list .sidebar img.dietary-property.is-dairy-free { background-color: blue; }
div.recipe-list .sidebar img.dietary-property.is-gluten-free { background-color: orange; }
div.recipe-list .sidebar img.dietary-property.is-vegan { background-color: lightgreen; }
div.recipe-list .sidebar img.dietary-property.is-vegetarian { background-color: green; }

div.recipe-list .sidebar span {
  display: inline-block;
  margin-left: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

div.recipe-list .content .ingredients {
  margin-top: 1rem;
  margin-left: 1rem;
  display: grid;
  grid-row-gap: 0.25em;
  grid-column-gap: 0.5em;
  grid-template-columns: fit-content(25%) auto;
}

div.recipe-list .content .ingredients .quantity {
  width: 90px;
  grid-column: 1;
  text-align: right;
}

div.recipe-list .content .ingredients .ingredient {
  grid-column: 2;
}

div.recipe-list .content .ingredients .ingredient .product {
  text-decoration-color: silver;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

div.recipe-list .content .ingredients .ingredient .product.available {
  text-decoration-color: orange;
}

div.recipe-list button.add-recipe {
  width: 192px;
  margin-top: 1px;
  margin-bottom: 4px;
}
