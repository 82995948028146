#search form {
  display: none;
}

#search div.card-body {
  padding-top: 0;
}

#search .prompt + span {
  vertical-align: middle;
  margin-left: 2rem;
}

#search select { width: 200px; }
#search .select2 { margin-top: 12px; }
#search li.select2-selection__choice, #search li.select2-search {
  display: inline-block;
  clear: both;
}

#search .include + span { color: #70f070; }
#search .exclude + span { color: #f07070; }

#search .dietary-properties ~ ul {
  list-style-type: none;
  overflow: hidden;
  margin-top: 1rem;
}

#search .prompt {
  display: block;
  margin-top: 1rem;
}

#search .refinements {
  background-color: #ffffc0;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

#search .refinements div {
  font-size: 13px;
  color: dimgray;

  margin: 0.5rem;
  margin-left: 2rem;
}

#search .domain-facets {
  margin-bottom: 1rem;
}

#search .domain-facets .badge input {
  margin-right: 4px;
  vertical-align: middle;
}
