#meal-planner .hints {
  font-size: 11px;
  color: dimgray;
  margin-bottom: 1rem;
}

#meal-planner .recipes {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 4px;
}

#meal-planner .meal {
  margin-top: 2px;
  background-color: white;
  border-color: silver;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  padding: 4px;
  font-size: 13px;
  user-select: none;
  width: fit-content;
}

#meal-planner a {
  color: inherit;
  text-decoration: inherit;
}

#meal-planner .meal a.link {
  color: red;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  margin-right: 0.5em;
}
#meal-planner .meal a.remove {
  color: red;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  margin-right: 0.5em;
}
#meal-planner .scheduler .meal a.remove {
  visibility: hidden;
  width: 0;
  margin: 0;
}
#meal-planner .sortable-drag a.remove {
  visibility: hidden;
}

#meal-planner .meal .servings {
  background-color: #b0b0ff;
  border-radius: 4px;
  padding: 4px;
  font-weight: bold;
}

#meal-planner .meal span[data-role="remove"]::after {
  color: red;
  content: '\00D7';
}
#meal-planner .meal span[data-role="remove"] {
  visibility: hidden;
  vertical-align: middle;
  font-weight: bold;
}
#meal-planner .scheduler .meal span[data-role="remove"] {
  visibility: visible;
  padding-left: 12px;
}
#meal-planner .sortable-drag span[data-role="remove"] {
  visibility: hidden;
}

#meal-planner .scheduler-navigation a.backward {
  float: left;
}

#meal-planner .scheduler-navigation a.forward {
  float: right;
}

#meal-planner .scheduler {
  width: 100%;
  height: calc(80vh);
  border: solid 2px silver;
}

#meal-planner .scheduler th {
  width: 96px;
  height: calc(15vh);

  padding: 12px;
  border-top: solid 1px silver;
  border-right: solid 1px #f0d0d0;

  text-align: right;
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
}

#meal-planner .scheduler th div.day {
  font-weight: bold;
}

#meal-planner .scheduler th div.date {
  color: gray;
}

#meal-planner .scheduler td {
  padding: 12px;
  border-top: solid 1px silver;

  vertical-align: top;
  text-align: left;
}

#meal-planner .scheduler tr.today {
  background-color: aliceblue !important;
}
#meal-planner .scheduler tr.weekday-6,
#meal-planner .scheduler tr.weekday-7 {
  background-color: beige;
}
