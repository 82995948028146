header {
  background-color: #005;
}

header h1 {
  color: white;
  display: inline;
  font-size: 1em;
  padding: 4px;
}

header a:hover {
  text-decoration: none;
}

header div.row {
  max-width: 90vw;
}

header div.menu {
  overflow: auto;
  background-color: #006;
}

header span.notification {
  border-radius: 2px;
  color: white;

  padding: 1px 3px;
  font-size: 10px;

  position: relative;
  top: -0.8rem;
  right: -0.2rem;
}

header span.notification.explore {
  background-color: #0000af;
}

header span.notification.meal-planner {
  background-color: #00af00;
}

header span.notification.shopping-list {
  background-color: #af0000;
}

li.select2-results__message {
  display: none;
}

li.select2-results__option.loading-results {
  display: none;
}

span.select2-dropdown {
  z-index: 1;
}

span.select2-dropdown--above {
  z-index: 2000;
}

footer {
  background-color: whitesmoke;
}

footer div {
  font-size: small;
  padding: 4px;
}

body {
  /* disable pull-down-to-refresh in chrome */
  overscroll-behavior: contain;

  /* sticky footer */
  /* ref: https://developer.mozilla.org/en-US/docs/Web/CSS/Layout_cookbook/Sticky_footers */
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

/* accessibility overrides: contrast workarounds for Bootstrap */
body a {
  /* bootstrap: color: #007bff; */
  color: #05d;
}

button.btn-primary {
  /* bootstrap: background-color: #007bff; */
  background-color: #0069d9;
}

button.btn-primary:hover {
  /* bootstrap: background-color: #0069d9; */
  background-color: #0057b7;
}

button.btn-outline-primary {
  /* bootstrap: color: #007bff; */
  color: #0069d9;
  /* bootstrap: border-color: #007bff; */
  border-color: #0069d9;
}

li.page-item a {
  /* bootstrap: color: #007bff; */
  color: #0069d9;
}
/* end accessibility overrides */
