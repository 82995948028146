#explore div.card-body {
  padding-top: 0;
}

#explore .prompt {
  display: block;
  margin-top: 1rem;
}

#explore-choices ul {
  list-style-type: none;
  overflow: hidden;
  margin-top: 1rem;
}

#explore-choices li {
  text-align: center;
  user-select: none;
  padding: 0.25rem;
}

#explore-choices li span {
  padding: 0.25rem;
  border-radius: 0.25rem;
  background-color: #f0f0f0;
}

#explore-choices li span.include {
  background-color: #b0ffb0;
}

#explore-choices li span.exclude {
  background-color: #ffb0b0;
}
