#shopping-list .hints {
  font-size: 11px;
  color: dimgray;
  margin-bottom: 1rem;
}

#shopping-list .products fieldset {
  border: solid 1px silver;
  margin-bottom: 1rem;
  padding: 4px;
}

#shopping-list .products fieldset legend {
  font-size: 1rem;
  width: auto;
  margin: 0;
}

#shopping-list .products fieldset.bakery { border-color: burlywood; }
#shopping-list .products fieldset.dairy { border-color: lightblue; }
#shopping-list .products fieldset.dry_goods { border-color: khaki; }
#shopping-list .products fieldset.fruit_and_veg { border-color: palegreen; }
#shopping-list .products fieldset.meat_and_deli { border-color: pink; }
#shopping-list .products fieldset.oil_and_vinegar_and_condiments { border-color: moccasin; }

#shopping-list .products label {
  display: block;
  width: 240px;
  margin: 3px;
  line-height: 1rem;
  font-size: 12px;
}
#shopping-list .products label input {
  margin-right: 4px;
  vertical-align: middle;
}
#shopping-list .products span[data-role="remove"]::after {
  color: red;
  content: '\00D7';
}
#shopping-list .products span[data-role="remove"] {
  float: right;
  vertical-align: middle;
  font-weight: bold;
}

#shopping-list .entry {
  margin-top: 1rem;
}
#shopping-list .entry span.prompt,
#shopping-list .entry label {
  font-size: 12px;
}

#shopping-list select { width: 200px; }
